.t_adaptive_page[data-v-11fc5ade] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.t_adaptive_page .left_content[data-v-11fc5ade] {
  background-color: #fff;
  margin: 8px;
  margin-right: 0;
}
.t_adaptive_page .left_content .left_tree[data-v-11fc5ade] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
}
.t_adaptive_page .right_content[data-v-11fc5ade] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.t_adaptive_page .right_content .table_main[data-v-11fc5ade] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
}
.t_adaptive_page .right_content .table_main .t-table[data-v-11fc5ade] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
}
.t_adaptive_page .right_content .table_main .t-table[data-v-11fc5ade] .el-table {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
}
.t_adaptive_page .right_content .table_main .t-table[data-v-11fc5ade] .el-table::before {
  height: 0px;
}
.t_adaptive_page .right_content .table_main .t-table[data-v-11fc5ade] .el-table .el-table__fixed::before,
.t_adaptive_page .right_content .table_main .t-table[data-v-11fc5ade] .el-table .el-table__fixed-right::before {
  height: 0px;
}