.t_layout_page[data-v-2aa3bbaa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.t_layout_page .back_to_top[data-v-2aa3bbaa] {
  position: fixed;
  right: 10px;
  bottom: 100px;
  z-index: 100;
  -webkit-box-shadow: 0 5px 6px 2px rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 6px 2px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 5px;
  opacity: 0.6;
}
.t_layout_page .back_to_top[data-v-2aa3bbaa]:hover {
  opacity: 1;
}
.t_layout_page .back_to_top > div[data-v-2aa3bbaa] {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t_layout_page .back_to_top .top_icon[data-v-2aa3bbaa] {
  font-size: 20px;
}