.t_upload_file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t_upload_file .el-upload-dragger {
  position: relative;
  overflow: initial;
}
.t_upload_file .el-upload-dragger .el-upload__text {
  width: 50%;
  margin-top: 22px;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.t_upload_file .upload-file-tips {
  text-align: left;
  line-height: 2;
}
.t_upload_file .el-upload-list {
  text-align: left;
  padding-left: 30px;
  max-height: 300px;
  overflow-y: auto;
}
.t_upload_file .el-upload-list .el-upload-list__item {
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
}
.t_upload_file .el-upload-list .el-upload-list__item .el-progress {
  top: 12px;
  height: 3px;
}
.t_upload_file .el-upload-list {
  padding: 0;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.t_upload_file .el-upload--picture-card {
  line-height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.t_upload_file .el-upload-list--picture-card {
  border: none;
}
.t_upload_file .el-upload-list--picture-card .is-ready {
  border: none;
}
.t_upload_file div:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.t_upload_file .dialog_preview .el-dialog__header {
  width: 100%;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap {
  font-size: 14px;
  line-height: 18px;
  padding: 0 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap > i.el-icon-document,
.t_upload_file #upload_list .el-upload-list__item .content_wrap span {
  color: #333;
  vertical-align: middle;
  line-height: 18px;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap > i.el-icon-circle-check {
  color: #67c23a;
  float: right;
  vertical-align: middle;
  line-height: 18px;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap > i.el-icon-circle-close {
  color: #f56c6c;
  float: right;
  vertical-align: middle;
  line-height: 18px;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap > span {
  cursor: pointer;
  display: inline-block;
  margin-left: 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.t_upload_file #upload_list .el-upload-list__item .content_wrap > .file_name:hover {
  color: #409eff;
  text-decoration: underline;
}
.t_upload_file #upload_list .el-upload-list__item > .content_wrap:hover {
  background: rgba(0, 0, 0, 0.035);
}
.t_upload_file.hide_file .el-upload--picture-card {
  display: none;
}
.t_upload_file .right_tip {
  position: absolute;
  color: #c0c4cc;
  width: 100%;
  left: 160px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}