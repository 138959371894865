.t_container {
  margin: auto;
}
.t_container li {
  list-style-type: none;
}
.t_container .t_top_changge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.t_container .t_top_changge .t_top_changge li {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #333;
  font-size: 18px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 47px;
}
.t_container .t_top_changge .prev-month {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 20px;
  -ms-flex-item-align: center;
      align-self: center;
  cursor: pointer;
}
.t_container .t_top_changge .next-month {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 20px;
  -ms-flex-item-align: center;
      align-self: center;
  cursor: pointer;
}
.t_container .t_top_changge .t_content_li {
  cursor: auto;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
.t_container .t_content_all {
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  padding-bottom: 8px;
}
.t_container .t_content .t_content_item:nth-child(7n+1) {
  color: #ccc;
}
.t_container .t_content .t_content_item:nth-child(7n+7) {
  color: #ccc;
}
.t_container .t_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 3% 0 3%;
  width: 100%;
}
.t_container .t_content:first-child .t_content_item_tag {
  color: #ddd;
  font-size: 16px;
}
.t_container .t_content:first-child .t_content_item {
  color: #ddd;
  font-size: 16px;
}
.t_container .t_content_item {
  font-size: 15px;
  width: 14.2857%;
  text-align: center;
  color: #333;
  position: relative;
  height: 40px;
  margin-top: 10px;
}
.t_container .t_content_item_tag {
  font-size: 15px;
  width: 13.4%;
  text-align: center;
  color: #333;
  position: relative;
}
.t_container .t_top_tag {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t_container .t_item_date {
  width: 30px;
  height: 30px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t_container .t_jiantou1 {
  width: 12px;
  height: 12px;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.t_container .t_jiantou1:active,
.t_container .t_jiantou2:active {
  border-color: #ddd;
}
.t_container .t_jiantou2 {
  width: 12px;
  height: 12px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.t_container .t_content_item > .t_isMark {
  margin: auto;
  border-radius: 100px;
  background: blue;
  z-index: 2;
}
.t_container .t_content_item .t_other_dayhide {
  color: #bfbfbf;
}
.t_container .t_content_item .t_want_dayhide {
  color: #bfbfbf;
}
.t_container .t_content_item .t_isToday {
  background: yellow;
  border-radius: 50%;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0% center;
          transform-origin: 0% center;
}
.t_container .t_content_item .t_chose_day {
  background: green;
  border-radius: 50%;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0% center;
          transform-origin: 0% center;
}