.scroll-container[data-v-be6b7bae] {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.scroll-container[data-v-be6b7bae]  .el-scrollbar__bar {
  bottom: 0px;
}
.scroll-container[data-v-be6b7bae]  .el-scrollbar__wrap {
  height: 49px;
}