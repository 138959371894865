.el-dropdown-menu[data-v-34128e60] {
  padding: 15px;
  font-size: 14px;
}
.el-dropdown-menu .title[data-v-34128e60] {
  font-weight: bold;
}
.el-dropdown-menu[data-v-34128e60]  .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #262626;
}
.el-dropdown-menu .t_table_column_setting_dropdown[data-v-34128e60] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}