.rightPanel-background[data-v-1e488bfb] {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.rightPanel[data-v-1e488bfb] {
  width: 100%;
  max-width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  -webkit-transform: translate(100%);
          transform: translate(100%);
  background: #fff;
  z-index: 40000;
}
.handle-button[data-v-1e488bfb] {
  width: 48px;
  height: 48px;
  position: absolute;
  left: -48px;
  text-align: center;
  font-size: 24px;
  border-radius: 6px 0 0 6px !important;
  z-index: 0;
  pointer-events: auto;
  cursor: pointer;
  color: #fff;
  line-height: 48px;
}
.handle-button i[data-v-1e488bfb] {
  font-size: 24px;
  line-height: 48px;
}