.el-image[data-v-4b9dcf40] {
  border-radius: 5px;
  background-color: #ebeef5;
  -webkit-box-shadow: 0 0 5px 1px #ccc;
          box-shadow: 0 0 5px 1px #ccc;
}
.el-image[data-v-4b9dcf40]  .el-image__inner {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.el-image[data-v-4b9dcf40]  .el-image__inner:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.el-image[data-v-4b9dcf40]  .image-slot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  color: #909399;
  font-size: 30px;
}