.i_layout .section .layout-content {
  padding: 0;
}
.step-wizard {
  position: relative;
}
.step-wizard .el-steps--simple {
  border-radius: 0px;
}
.step-wizard .content-step-main .step-last .icon-success {
  color: #67c23a;
  font-size: 95px;
  margin-top: 40px;
}
.step-wizard .content-step-main .step-last .success-margin {
  margin-bottom: 70px;
}