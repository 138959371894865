.custom-dropdown-menu[data-v-d16d6306] {
  max-height: 100px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.custom-dropdown-menu[data-v-d16d6306]:hover {
  background-color: white;
}
.custom-dropdown-menu-item[data-v-d16d6306] {
  cursor: default;
}
.custom-dropdown-menu-item[data-v-d16d6306]:hover {
  background-color: white;
  color: #606266;
}
.has-unread[data-v-d16d6306] {
  position: relative;
}
.unread-count[data-v-d16d6306] {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
}
.el-icon-bell-large[data-v-d16d6306] {
  font-size: 25px;
  margin-top: 12px;
  cursor: pointer;
}
.navbar[data-v-d16d6306] {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.navbar .hamburger-container[data-v-d16d6306] {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.navbar .hamburger-container[data-v-d16d6306]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .breadcrumb-container[data-v-d16d6306] {
  float: left;
}
.navbar .topmenu-container[data-v-d16d6306] {
  position: absolute;
  left: 50px;
}
.navbar .errLog-container[data-v-d16d6306] {
  display: inline-block;
  vertical-align: top;
}
.navbar .right-menu[data-v-d16d6306] {
  width: 250px;
  float: right;
  height: 100%;
  line-height: 50px;
}
.navbar .right-menu[data-v-d16d6306]:focus {
  outline: none;
}
.navbar .right-menu .right-menu-item[data-v-d16d6306] {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306] {
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .right-menu .avatar-container[data-v-d16d6306] {
  margin-right: 30px;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-d16d6306] {
  margin-top: 5px;
  position: relative;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-avatar[data-v-d16d6306] {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom[data-v-d16d6306] {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 25px;
  font-size: 12px;
}