.t_select_table_tag_del[data-v-03a0b685]  .el-tag .el-tag__close {
  display: none;
}
.t-select-table .radioStyle[data-v-03a0b685]  .el-radio .el-radio__label {
  display: none;
}
.t-select-table .radioStyle[data-v-03a0b685]  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.t-select-table .radioStyle[data-v-03a0b685]  tbody .el-table__row {
  cursor: pointer;
}
.t-select-table .highlightCurrentRow[data-v-03a0b685]  tbody .el-table__row {
  cursor: pointer;
}
.t-select-table .highlightCurrentRow[data-v-03a0b685]  tbody .current-row td {
  cursor: pointer;
  color: #409eff;
}
.t-select-table .keyUpStyle[data-v-03a0b685]  tbody .el-table__row {
  cursor: pointer;
}
.t-select-table .keyUpStyle[data-v-03a0b685]  tbody .current-row td {
  cursor: pointer;
  color: #409eff;
}
.t-select-table .t-table-select__table[data-v-03a0b685]  tbody .keyup-hover-row {
  color: red;
}
.t-select-table .t-table-select__table[data-v-03a0b685] {
  padding: 10px;
}
.t-select-table .t-table-select__table .table_query_condition[data-v-03a0b685] {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
}
.t-select-table .el-table .el-tooltip div[data-v-03a0b685] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
}
.t-select-table .el-table .el-tooltip .el-form-item[data-v-03a0b685] {
  overflow: visible;
}
.t-select-table .t-table-select__page[data-v-03a0b685] {
  padding-top: 5px;
}
.t-select-table .t-table-select__page[data-v-03a0b685]  .el-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: calc(2% - 20px);
  background-color: #fff;
}