
.svg-icon[data-v-c8a70580] {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.svg-external-icon[data-v-c8a70580] {
  background-color: currentColor;
  -webkit-mask-size: cover!important;
          mask-size: cover!important;
  display: inline-block;
}
