.t-query-condition.el-form {
  position: relative;
  display: grid;
  gap: 2px 8px;
  margin-bottom: -7px;
  text-align: left;
}
.t-query-condition.el-form .el-select,
.t-query-condition.el-form .el-date-editor,
.t-query-condition.el-form .ant-calendar-picker {
  width: 100%;
}
.t-query-condition.el-form .flex_end {
  grid-area: submit_btn;
  margin-top: 2px;
}
.t-query-condition.el-form .flex_end .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: visible !important;
}
.t-query-condition.el-form .btn {
  text-align: end;
}
.t-query-condition.el-form .render_label .el-form-item__label {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.t-query-condition.el-form .render_label .el-form-item__label::before {
  margin-top: 1px;
}
.t-query-condition.el-form .el-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 6px;
}
.t-query-condition.el-form .el-form-item .el-form-item__label {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  min-width: 60px;
  padding-left: 8px;
}
.t-query-condition.el-form .el-form-item .el-form-item__content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 0 !important;
}
.t-query-condition.el-form .btn_check {
  position: relative;
  top: -1px;
}
.t-query-condition.el-form .btn_reset {
  position: relative;
  top: -1px;
  margin-left: 8px;
}
.t-query-condition.el-form [hidden] {
  display: none !important;
}