.t-search {
  padding: 20px 0;
  width: 435px;
}
.t-search:before {
  content: "" !important;
}
.t-search .search-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.t-search .search-main .el-input-group__prepend {
  background-color: #fff;
  width: 130px;
}
.t-search .search-main .el-input-group__prepend .el-input__inner {
  text-align: center;
  width: 130px !important;
}
.t-search .search-main .search-left .el-input__inner {
  width: 260px;
}
.t-search .search-main button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.t-search .search-right {
  margin-left: 20px;
}
.t-search .search-right div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}