.t_select .all_checkbox {
  margin-left: 15px;
}
.t_select .el-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t_select .el-pagination .el-pagination__total,
.t_select .el-pagination .el-pager,
.t_select .el-pagination button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}