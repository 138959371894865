.t-edit-table[data-v-abc75ada] {
  z-index: 0;
  background-color: #fff;
  padding: 10px;
}
.t-edit-table[data-v-abc75ada]  .el-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 20px;
  margin-right: calc(2% - 20px);
  background-color: #fff;
}
.t-edit-table .el-table th[data-v-abc75ada],
.t-edit-table .el-table td[data-v-abc75ada] {
  padding: 8px 0;
}
.t-edit-table .el-table--border th:first-child .cell[data-v-abc75ada],
.t-edit-table .el-table--border td:first-child .cell[data-v-abc75ada] {
  padding-left: 5px;
}
.t-edit-table .el-table .cell[data-v-abc75ada] {
  padding: 0 5px;
}
.t-edit-table .el-table--scrollable-y .el-table__fixed-right[data-v-abc75ada] {
  right: 8px !important;
}
.t-edit-table .header_wrap[data-v-abc75ada] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t-edit-table .header_wrap .header_btn[data-v-abc75ada] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 30%;
          flex: 0 30%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 35px;
  margin-left: 10px;
}
.t-edit-table .el-table--scrollable-y .el-table__body-wrapper[data-v-abc75ada] {
  overflow-x: auto;
}
.t-edit-table .handle_wrap[data-v-abc75ada] {
  position: sticky;
  z-index: 10;
  right: 0;
  bottom: -8px;
  margin: 0 -8px -8px;
  padding: 12px 16px;
  background-color: #fff;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
.t-edit-table .handle_wrap .el-btn[data-v-abc75ada] {
  margin-left: 8px;
}