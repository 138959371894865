.t_layout[data-v-e9708d9c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.t_layout .section[data-v-e9708d9c] {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
}
.t_layout .section .sectionTitle[data-v-e9708d9c] {
  height: 50px;
  border-bottom: 1px solid #eee;
  color: #666;
  line-height: 50px;
  padding: 0 20px;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.t_layout .section .layout-content[data-v-e9708d9c] {
  font-size: 14px;
  text-align: left;
  position: relative;
}
.t_layout .section .layout-content .fix-btn[data-v-e9708d9c] {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background: #fff;
}
.t_layout .section .layout-content .footer[data-v-e9708d9c] {
  font-size: 12px;
  color: #999;
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}