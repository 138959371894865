.t_form .el-collapse {
  border: none;
  background-color: #f0f2f5;
}
.t_form .el-collapse .el-collapse-item {
  background-color: #fff;
  margin-top: 10px;
  border: none;
}
.t_form .el-collapse .el-collapse-item .el-collapse-item__header {
  border-bottom: 1px solid #ebeef5;
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.t_form .el-collapse .el-collapse-item .el-collapse-item__header .t_btn {
  margin-right: 15px;
}
.t_form .el-collapse .el-collapse-item .el-collapse-item__header .el-collapse-item__arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
}
.t_form .el-collapse .el-collapse-item .el-collapse-item__wrap {
  padding: 16px;
  border: none;
}
.t_form .el-collapse .el-collapse-item .el-collapse-item__wrap .el-collapse-item__content {
  padding-bottom: 0;
}
.t_form .el-collapse .noTitle > div:first-child {
  display: none;
}
.t_form .el-collapse .disabledStyle .el-collapse-item__header {
  color: #303133;
  cursor: default;
  padding-left: 20px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.t_form .el-collapse .disabledStyle .el-collapse-item__header .el-collapse-item__arrow {
  display: none;
}
.t_form .el-collapse .disabledStyle .el-collapse-item__header .t_btn {
  margin-right: 15px;
}