/**
$base-menu-color:hsla(0,0%,100%,.65);
$base-menu-color-active:#fff;
$base-menu-background:#001529;
$base-logo-title-color: #ffffff;

$base-menu-light-color:rgba(0,0,0,.70);
$base-menu-light-background:#ffffff;
$base-logo-light-title-color: #001529;

$base-sub-menu-background:#000c17;
$base-sub-menu-hover:#001528;
*/
[data-v-13877386]:export {
  menuColor: #bfcbd9;
  menuLightColor: rgba(0, 0, 0, 0.7);
  menuColorActive: #f4f4f5;
  menuBackground: #304156;
  menuLightBackground: #ffffff;
  subMenuBackground: #1f2d3d;
  subMenuHover: #001528;
  sideBarWidth: 200px;
  logoTitleColor: #ffffff;
  logoLightTitleColor: #001529;
}
.app-wrapper[data-v-13877386] {
  position: relative;
  height: 100%;
  width: 100%;
}
.app-wrapper[data-v-13877386]:after {
  content: "";
  display: table;
  clear: both;
}
.app-wrapper.mobile.openSidebar[data-v-13877386] {
  position: fixed;
  top: 0;
}
.drawer-bg[data-v-13877386] {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.fixed-header[data-v-13877386] {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 200px);
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
}
.hideSidebar .fixed-header[data-v-13877386] {
  width: calc(100% - 54px);
}
.sidebarHide .fixed-header[data-v-13877386] {
  width: 100%;
}
.mobile .fixed-header[data-v-13877386] {
  width: 100%;
}