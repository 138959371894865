.fix-btn[data-v-152e0f36] {
  min-width: 990px;
  max-width: 1840px;
  width: 100%;
}
.bottom-tool-bar[data-v-152e0f36] {
  height: 60px;
  text-align: center;
  margin: 10px 0;
  background: #fff;
}
.bar-fixed-bottom[data-v-152e0f36] {
  position: absolute;
  margin: 0 !important;
  z-index: 99;
  bottom: 0;
  left: 0;
}