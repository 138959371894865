.upload-file-uploader[data-v-211f81e0] {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item[data-v-211f81e0] {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content[data-v-211f81e0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link[data-v-211f81e0] {
  margin-right: 10px;
}