.t-dialog .t-dialog-content .el-dialog__header {
  border-bottom: 1px solid #f0f2f5;
}
.t-dialog .t-dialog-content .el-dialog__body {
  overflow-x: auto;
}
.t-dialog .t-dialog-content .el-dialog__body::-webkit-scrollbar {
  margin-top: 110px;
  width: 8px;
  height: 1px;
}
.t-dialog .t-dialog-content .el-dialog__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #b3b3b3;
}
.t-dialog .t-dialog-content .el-dialog__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #ededed;
}