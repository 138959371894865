.t-tree-select .check-box[data-v-73b98ea3] {
  padding: 0 20px;
}
.t-tree-select .option-style[data-v-73b98ea3] {
  height: 100%;
  max-height: 300px;
  margin: 0;
  overflow-y: auto;
  cursor: default !important;
}
.t-tree-select .tree-style[data-v-73b98ea3]  .el-tree-node.is-current > .el-tree-node__content {
  color: #3370ff;
}
.t-tree-select .el-select-dropdown__item.selected[data-v-73b98ea3] {
  font-weight: 500;
}
.t-tree-select .el-input__inner[data-v-73b98ea3] {
  height: 36px;
  line-height: 36px;
}
.t-tree-select .el-input__icon[data-v-73b98ea3] {
  line-height: 36px;
}
.t-tree-select .el-tree-node__content[data-v-73b98ea3] {
  height: 32px;
}