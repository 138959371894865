.panel-content[data-v-a83bd3b0] {
  height: 100px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
          box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  font-size: 16px;
}
.panel-top-left[data-v-a83bd3b0] {
  position: absolute;
  top: 10px;
  left: 10px;
}
.panel-number[data-v-a83bd3b0] {
  font-size: 24px;
}
.arrow-info[data-v-a83bd3b0] {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.arrow-info i[data-v-a83bd3b0] {
  font-size: 18px;
  margin-right: 5px;
}
.search-chart-container[data-v-a83bd3b0] {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.search-form[data-v-a83bd3b0] {
  margin-bottom: 20px;
}
h2[data-v-a83bd3b0] {
  margin-left: 10px;
}
@media (max-width: 1024px) {
.chart-wrapper[data-v-a83bd3b0] {
    padding: 8px;
}
}