.sms-code .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sms-code .timer .sendSmsBtn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-bottom: 1px;
}
.sms-code .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}