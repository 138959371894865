.timer-btn[data-v-77ab52a8] {
  position: relative;
}
.timer-btn .sendSmsBtn[data-v-77ab52a8] {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #ef473a;
  border: none;
  padding: 0 6px;
  color: #fff;
  display: inline-block;
  min-width: 92px;
  cursor: pointer;
}
.timer-btn .dissendSmsBtn[data-v-77ab52a8] {
  opacity: 0.5;
  cursor: auto;
}