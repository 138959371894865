.input-search-select[data-v-0dbf7f53] {
  position: relative;
}
.input-search-select[data-v-0dbf7f53]  .el-input-group__append {
  padding: 0 17px;
}
.input-search-select .select-list[data-v-0dbf7f53] {
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0 0 5px #ccc;
          box-shadow: 0 0 5px #ccc;
  border-radius: 4px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.input-search-select .select-list > div[data-v-0dbf7f53] {
  min-height: 100px;
}
.input-search-select .select-list .li[data-v-0dbf7f53] {
  border-bottom: 1px solid #f1f1f1;
  padding: 0 10px;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.input-search-select[data-v-0dbf7f53]  .el-loading-spinner .circular {
  height: 26px;
  width: 26px;
}
.input-search-select .load-more[data-v-0dbf7f53] {
  border: 0;
  width: 100%;
  border-radius: 0;
}