.t-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.t-form .el-form-item {
  display: inline-block;
  width: 50%;
}
.t-form .el-form-item .el-form-item__content .el-input,
.t-form .el-form-item .el-form-item__content .el-select,
.t-form .el-form-item .el-form-item__content .el-date-editor,
.t-form .el-form-item .el-form-item__content .el-textarea {
  width: 100%;
}
.t-form .el-form-item .el-form-item__content .el-input-number .el-input {
  width: inherit;
}
.t-form .is_dynamic .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t-form .is_dynamic .el-form-item__content .el-icon-delete {
  margin-left: 5px;
  cursor: pointer;
}
.t-form .t-margin-top-5 {
  margin-top: calc(5px);
}
.t-form .el-input-number .el-input .el-input__inner {
  text-align: left;
}
.t-form .render_label .el-form-item__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.t-form .render_label .el-form-item__label::before {
  margin-top: 1px;
}
.t-form .render_laber_position .el-form-item__label {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.t-form .label_render {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.t-form .slot_label .el-form-item__content label {
  min-width: 108px;
  color: #606266;
  text-align: right;
  margin-right: 12px;
}
.t-form .footer_btn {
  width: 100%;
}