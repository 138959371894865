.t_module_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  text-align: left;
  background-color: #f0f2f5;
  overflow: auto;
}
.t_module_form .scroll_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.t_module_form .scroll_wrap .el-page-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__left {
  color: #303133;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__left .el-icon-back {
  font-weight: bold;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__left .el-page-header__title {
  font-size: 18px;
  font-weight: bold;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 60%;
      -ms-flex: 60%;
          flex: 60%;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__content .sub_title {
  -webkit-box-flex: 30%;
      -ms-flex: 30%;
          flex: 30%;
}
.t_module_form .scroll_wrap .el-page-header .el-page-header__content .extra {
  -webkit-box-flex: 70%;
      -ms-flex: 70%;
          flex: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.t_module_form .scroll_wrap .noContent .el-page-header__left::after {
  content: none;
}
.t_module_form .scroll_wrap .isShowBack .el-page-header__left .el-icon-back {
  display: none;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless {
  background-color: #f0f2f5;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless .noTitle .el-collapse-header {
  display: none;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless .el-collapse-item {
  background-color: #fff;
  margin-top: 10px;
  border: none;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless .el-collapse-item:first-child {
  margin-top: 0;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless .el-collapse-item .el-collapse-header {
  border-bottom: 1px solid #d9d9d9;
}
.t_module_form .scroll_wrap .t_form .el-collapse-borderless .el-collapse-item .el-collapse-content-box {
  padding: 16px;
}
.t_module_form .scroll_wrap .tabs {
  padding: 0;
  margin: 0;
}
.t_module_form .scroll_wrap .tabs .el-tabs .el-tabs__header {
  margin: 0;
  padding: 0 10px;
  background-color: #fff;
}
.t_module_form .scroll_wrap .tabs .el-tabs .el-tabs__nav-wrap::after {
  height: 1px;
}
.t_module_form .handle_wrap {
  position: fixed;
  z-index: 4;
  right: 0;
  bottom: 0px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background-color: #fff;
  border-top: 1px solid #ebeef5;
  text-align: right;
  width: 100%;
}
.t_module_form .handle_wrap .el-button:last-child {
  margin-right: 15px;
}